import React from "react"


function Tech4Climate(props) {
    return (
        <>
            <div className="UI-content">
                <div className="intro">
                    <h1>Tech 4 climate</h1>
                    <h3>Branding and websitedesign</h3>
                </div>

                <div className="project-description">
                    <h3>What and Why</h3>
                    <p>In my third semester I created branding assets and a website design for Tech 4 Climate. Tech 4 Climate is a project by my father Arnold Stokking and aims to and share knowledge about sustainable industrial production technologies to help companies explore environment friendly production alternatives for industrial production facilities in the Netherlands.</p>
                    <img src={require('./Media/Tech4Climate/LogoDev.png')} className="Logodev" />
                    <p className=".imageDescription">1st iteration of the logo design</p>
                    <p>The branding needed to appeal to people who are involved in set industrial environments and give them an exciting and hopeful impression.</p>
                    <img src={require('./Media/Tech4Climate/LogoDev03.png')} className="Logodev" />
                    <p className=".imageDescription">2nd iteration of the logo design</p>

                    <p>I started out with exploring what similar projects/companies were doing with their branding.
                        I came up with a few basic branding and website designs and presented those to my client.</p>

                        
                        <img src={require('./Media/Tech4Climate/LogoDev04.png')} className="Logodev" />
                        <p>The final logo and color design.</p>
                     <p>   
                        Based on my client’s feedback I improved on my designs and found a direction to pursue.
                        I later requested feedback from my peers and concluded that I might be better off redoing the design and start over.
                    </p>
                    <img src={require('./Media/Tech4Climate/Firstdesigns.png')} className="Logodev" />
                    

                    <p className=".imageDescription">The final design and colors.</p>
                    <p>The final website design and branding are visible with this adobe XD <a href="https://xd.adobe.com/view/199e6d25-413d-405a-a56c-7a9c69b5c30f-4ccd/">link</a>.</p>
                    <img src={require('./Media/Tech4Climate/Endproduct.jpg')} className="Logodev" />
                </div>
                <div className="design-research"></div>
            </div>
            
        </>
    )
}

export default Tech4Climate;