import React from 'react'
import Wave from 'react-wavify'

const WavE = () => (
  <Wave fill="url(#gradient)"
        paused={false}
        options={{
          height: 100,
          amplitude: 20,
          speed: 0.15,
          points: 3
          
        }}
  >
  <defs>
    <linearGradient id="gradient" gradientTransform="rotate(30)">
      <stop offset="10%"  stopColor="#B761A3" /> 
      <stop offset="90%" stopColor="#ED6E52" /> 
    </linearGradient>
<style ></style>
  </defs>
  
</Wave>
)

export default WavE