import React from "react"
import './App.css';
import WavE from "./wavytest";
import { BrowserRouter, Route, Link, Routes, Outlet, } from 'react-router-dom';

import Thumbnail from "./Thumbnail";


function Projects(props) {
  return (
    <>

      <div className="UI-content">

        <div className="intro">

          <h1>Projects</h1>
          <h3>Fontys group assignments and smaller projects</h3>


        </div>
        <div className="thumbnails d-flex justify-content-center"> 
          <Thumbnail projectname="Eurocom" projectdesc="Dashboard for carehome personel to keep track of the wellbeeing of their clients with biometric data" projectlink="Projects/Eurocom"/>
          <Thumbnail projectname="Tech4Climate" projectdesc="Design and branding for a brand that aims to promote people for green industrial technologies" projectlink="Projects/Tech4Climate"/>
          <Thumbnail projectname="Fitness Assistant" projectdesc="Concept and technical prototype for a digital fitness assistant powered by a machineleared model" projectlink="Projects/FitnessAssistant"/>
          <Thumbnail projectname="PortfolioSite" projectdesc="(Empty) About this portfolio site that i am still working on" projectlink="Projects/PortfolioSite"/>
          <Thumbnail projectname="ClubofFit" projectdesc="Branding for my first e-commerce platform that could" projectlink="Projects/ClubofFit"/>
{/* 
          <Link to="Projects/Eurocom" className="item">  Eurocom,</Link>
          <Link to="Projects/Tech4Climate" className="item">  Tech4Climate, </Link>
          <Link to="Projects/FitnessAssistant" className="item">  Fitness Assistant, </Link>
          <Link to="Projects/PortfolioSite" className="item">  PortfolioSite, </Link>
          <Link to="Projects/ClubofFit" className="item">  ClubofFit</Link> */}


          {/* <Link to="Projects/Eurocom" className="item"><img className="thumbnail" src={require('./Products/Media/Eurocom/prototypesketch.png')} alt="test" /></Link> */}
          
        </div>
        <div className="footer">
          <div className="mirror">
            <WavE />
          </div>
        </div>
      </div>
    </>

  );
}

export default Projects;