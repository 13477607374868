import React from "react"
function ClubofFit(props) {
    return (
        <>
            <div className="UI-content">
                <div className="intro">
                    <h1>Club of Fit</h1>
                    <h3>Sporting suplements</h3>
                </div>

                <div className="project-description">
                    <h3>E-commerce</h3>
                    <p>During my first semester at Fontys I met someone who later became a good friend of mine and we shared interest in fitness and e-commerce. We decided that we would like to attempt combining those interests and launch our own registered web shop selling fitness and dietary supplements. We knew from the start that we probably won’t last a long time and the main reason for us to register our company at the KVK was to be able to contact and purchase from supplement suppliers. We found out that we could not make a profit with selling and shipping products without making a substantial investment first.</p>
                    
                    <p>I learned a lot by attempting to start up a company and get into ecommerce.</p>

                    <p>I created the branding, and my partner would host and develop our site using Wordpress. We figured that our target audience are mostly males aged 18 to 30-something who enjoy fitness and are interested in enhancing their experience with supplements like protein powder or pre-workout formula powders. </p>
                    <img src={require('./Media/ClubOfFit/COF logo.png')} className="Logodev" />
                    <p>The final logo and color design.</p>

                    <p>I started sketching and came up with a bolt logo that used a bright contrasting color. This formed an aggressive look that fitted with how we wanted our brand to be perceived: Bold and somewhat aggressive. </p>
                    <img src={require('./Media/ClubOfFit/BusinesscardCOF.png')} className="Logodev" />
                    <p>Our businesscard design.</p>
                </div>

                <div className="design-research"></div>



            </div>

        </>


    )
}
export default ClubofFit;