import React from "react";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { BrowserRouter, Route, Link, Routes, Outlet, } from 'react-router-dom';

function Thumbnail(props, projectname, projectdesc, projectlink) {
return(

      <Card style={{ width: '18rem', backgroundcolor: 'custom-color'}}>
    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}

    <Card.Body>
      <Card.Title>{props.projectname}</Card.Title>
      <Card.Text>
        {props.projectdesc}
      </Card.Text>
      <Link to={props.projectlink} className="item"><Button variant="outline-dark">View</Button></Link>
      {/* {this.props.projectlink} */}
      
    </Card.Body>
  </Card>
//  <Link to="Projects/Eurocom" className="item"><Button variant="primary">View</Button></Link>
//  A dashboard for carehomes to track the wellbeeing and status of their patiens at a glance. Made in React.

);
}

export default Thumbnail