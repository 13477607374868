// src/Projects.js
import Button from 'react-bootstrap/Button';
import React from "react"
import WavE from './wavytest';

const Introductie  = <h1>Hello, world</h1>;
 
function About(props) {
  return (
    <>
    <div className="UI-content">
    <div className="intro">
      <h1>About me</h1>
      <h3>Pieter Stokking</h3>
    </div><div className="project-description">
      <p>My name is Pieter Stokking and im a aspiring webdeveloper.</p>
      <p>More info will be added later</p>
      </div>
      
      </div>
      </>


  )
}
 
export default About;