import React from 'react';
import { BrowserRouter, Route, Link, Routes, Outlet, } from 'react-router-dom';
import Projects from './Projects.js';
import About from './About.js';
import Eurocom from './Products/Eurocom';
import FitnessAssistant from './Products/FitnessAssistant';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss'
import ClubofFit from './Products/ClubOfFit.js';
import Tech4Climate from './Products/Tech4Climate.js';
import PortfolioSite from './Products/PortfolioSite.js';
import WavE from './wavytest.js';



function App() {
  return (
    <BrowserRouter>
      <div className="App">
      <WavE />
          
        <div className="navigation">
          {/* <ul className="navigation-sub">
            
            <Link to="/" className="nav-item" style={{ textDecoration: 'none' }}> Projects,</Link>
            <Link to="/about" className="nav-item" style={{ textDecoration: 'none' }}> About Pieter</Link>

          </ul> */}
          <Outlet />
        </div>

        {/* // Set up the Router */}
        <Routes>
          <Route exact path="*" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/Projects/Eurocom" element={<Eurocom />} />
          <Route path="/Projects/FitnessAssistant" element={<FitnessAssistant />} />
          <Route path="/Projects/ClubOfFit" element={<ClubofFit />} />
          <Route path="/Projects/Tech4Climate" element={<Tech4Climate />} />
          <Route path="/Projects/PortfolioSite" element={<PortfolioSite />} />


          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>;
              </main>
            }
          />

        </Routes>
            
        
      </div>
    </BrowserRouter>

  );
}

export default App;

// const rootElement = document.getElementById("root")





