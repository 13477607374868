import React from "react";
const Introductie  = <h1>Hello, world</h1>;
 
function FitnessAssistant(props) {
  return (
    <div className="UI-content">
    <div className="intro">
      <h1>Fitness assistent</h1>
      <h3>body recognition based fitness assistant</h3>
    </div>
    

    <div className="project-description">
    <p>In my second semester I created a concept called Fitness assistant. The project was concluded with a working proof of concept.
The purpose of the concept is to assist a person in correctly preforming free weight fitness exercises. It does so by actively scanning a person’s body using <a href="https://blog.tensorflow.org/2018/05/real-time-human-pose-estimation-in.html">PosenetJS</a> and suggesting form improvements though an intuitive UI. 
I validated my concept by interviewing a physiotherapist who approved my idea and thought it would have a real world use. He gave me feedback and tips on how to further improve my concept.

</p>
<img src={require('./Media/FitnessAssistant/Adobe XD screenshot.png')} className="Logodev" />
<p>Based on his feedback I created an <a href="https://xd.adobe.com/view/4afa70c1-1d31-40e1-8ace-acd3e7c980e6-1fa9/">Adobe XD prototype</a> for the user interface and included the features he suggested it should have.</p>

<p>Meanwhile I continued on the technical proof of concepts and finished my semester with a working prototype of the technology that could scan the nose of a person using a webcam and comparing its position relative to a predefined value.</p>

<img src={require('./Media/FitnessAssistant/posenet score.PNG')} className="Logodev" />
<p>The Posenet values read of listed in a array</p>

<p>Meanwhile I continued on the technical proof of concepts and finished my semester with a working prototype of the technology that could scan the nose of a person using a webcam and comparing its position relative to the position of my nose form a photo I used as an predefined example.</p>
<img src={require('./Media/FitnessAssistant/Match my nose red.png')} className="Logodev" />
<img src={require('./Media/FitnessAssistant/match my nose green.png')} className="Logodev" />
<p>A screenshot of me matching the position of my nose with the nose of the photo.</p>

<p>The proof of concept used a javascript canvas element that automatically updated to draw the nose marker. and displayes the distance from webcamfeed nose to the example nose in pixels. </p>


<p></p>



    </div>



    </div>
  )
}
 
export default FitnessAssistant;