import React from "react"
function Eurocom(props) {
  return (
    <>
      <div className="UI-content">
        <div className="intro">
          <h1>Eurocom semester asignment</h1>
          <h3>An insightfull dashboard for healthcare profesionals.</h3>
        </div>

        <div className="project-description">
          <h3>Healthcare Dashboard</h3>
          
          <p>In my third semester I worked on a group assignment with five other students from a external company called Eurocom.
              Eurocom is a partner of Fontys and currently developing a health monitoring system called “Open Care Connect”. OCC is intended for care homes and other nursing environments to assist caretaking professionals providing preventative care for their clients. By providing preventative care, the risk of gaining new or worsening existing complications for nursing home residents can be decreased.</p>
              <img src={require('./Media/Eurocom/EurocomLogo.png')} className="Logodev" /> 

          <p>The dashboard is intended to be a module in a app from Eurocom. The dashboard’s main purpose is to assist the caretaking professional preforming preventative care by displaying conclusive wellbeing indicators based upon the measured data from e-health devices and sensors. </p>
          <p>The final product is visible with this <a href="https://eurocom-ac2b8.firebaseapp.com/">link</a>. Note that the application is set up as a mobile webapp. To view detailed resident information click the names or profile images.</p>
          <p>This assignment introduced me to React and was the first application that I worked on that got fully developed with a high-fidelity prototype and potentially will see real world use.</p>
          
          <img src={require('./Media/Eurocom/prototypesketch.png')} className="Logodev" />
          <img src={require('./Media/Eurocom/prototypesketch2.png')} className="Logodev" />           
          <p>Early sketches of the dashboard interface.</p>


          <p>To find out who our end user was going to be, my group and I preformed interviews with doctors, nurses and professional caretakers to better understand their role in a caretaking environment.</p>
         
          <img src={require('./Media/Eurocom/prototype2.png')} className="Logodev"  /> 
          <img src={require('./Media/Eurocom/prototype1.png')} className="Logodev"  /> 
          <p>Adobe XD prototype variants.</p>

          <p>based on our research findings we brainstormed what features would be helpful and created sketches of our ideas. After Eurocom gave us feedback on our sketches we created the first iteration of the Adobe XD prototype. </p>
          <p>Our adobe XD file is visible with this <a href="https://xd.adobe.com/view/a75ff7e9-bbb9-464b-b2b6-11e1b0c19ad2-f20c/">link</a>.</p>
          

          <img src={require('./Media/Eurocom/prototypefinal.png')} className="Logodev"  />
          <p>The final dashboard design.</p>

          <p>At the development stage I participated on the frond dashboard with two other students and the other students developed the “resident details” page that displays the biometric data of the residents. We split up our workload with react components. My participation were the following items I marked on the image below.</p>
          <img src={require('./Media/Eurocom/finalprototypetags.png')} className="Logodev"  />
          <p>The final design marked.</p>

        </div>

        

        <div className="design-research"></div>
      </div>

    </>


  )
}

export default Eurocom;