import React from "react"
function PortfolioSite(props) {
  return (
    <>
    <div className="UI-content">
    <div className="intro">
      <h1>Pieter's Portfolio</h1>
      <h3>This portfolio site I build with React.js</h3>
    </div>

    <div className="project-description">
      <h3>This page will be added later</h3>
      {/* <p>Eurocom is a company that is developing a health monitoring system called “Open Care Connect”. OCC is intended for care homes and other nursing environments to assist caretaking professionals providing preventative care for their clients. By providing preventative care, the risk of gaining new or worsening existing complications for nursing home residents can be decreased.</p> */}
      {/* <p>The dashboard is intended to be a module in a app from Eurocom. The dashboard’s main purpose is to assist the caretaking professional preforming preventative care by displaying conclusive wellbeing indicators based upon the measured data from e-health devices and sensors. </p> */}
    </div>

    <div className="design-research"></div>
    </div>

    </>


  )
}
 
export default PortfolioSite;